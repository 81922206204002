import { TCampagneDetail, TCampagneItem } from "@/types";
import { CampagneAction } from "./campagne.actions";
import campagneTypes from "./campagne.types";

const data: Array<TCampagneItem> = [
  // {
  //   id: 1,
  //   libelle: "CAMPAGNE 11",
  //   type: { sms: true, mail: false, whatsapp: false },
  //   description:
  //     "Lorem ipsuplds csdjcisnvsjvzb vzjev zeh vkz ev zekhvkzejv zev zeh vkze vkz  zkj",
  //   isActive: false,
  // },
  // {
  //   id: 2,
  //   libelle: "CAMPAGNE 22",
  //   type: { sms: false, mail: true, whatsapp: false },
  //   description:
  //     "Lorem ipsuplds csdjcisnvsjvzb vzjev zeh vkz ev zekhvkzejv zev zeh vkze vkz  zkj",
  //   isActive: false,
  // },
  // {
  //   id: 3,
  //   libelle: "CAMPAGNE 32",
  //   type: { sms: false, mail: true, whatsapp: true },
  //   description:
  //     "Lorem ipsuplds csdjcisnvsjvzb vzjev zeh vkz ev zekhvkzejv zev zeh vkze vkz  zkj",
  //   isActive: false,
  // },
  // {
  //   id: 4,
  //   libelle: "CAMPAGNE 41",
  //   type: { sms: false, mail: true, whatsapp: false },
  //   description:
  //     "Lorem ipsuplds csdjcisnvsjvzb vzjev zeh vkz ev zekhvkzejv zev zeh vkze vkz  zkj",
  //   isActive: false,
  // },
  // {
  //   id: 5,
  //   libelle: "CAMPAGNE 53",
  //   type: { sms: false, mail: true, whatsapp: true },
  //   description:
  //     "Lorem ipsuplds csdjcisnvsjvzb vzjev zeh vkz ev zekhvkzejv zev zeh vkze vkz  zkj",
  //   isActive: false,
  // },
  // {
  //   id: 6,
  //   libelle: "CAMPAGNE 61",
  //   type: { sms: false, mail: false, whatsapp: true },
  //   description:
  //     "Lorem ipsuplds csdjcisnvsjvzb vzjev zeh vkz ev zekhvkzejv zev zeh vkze vkz  zkj",
  //   isActive: false,
  // },
];

interface ICampagneReducerState {
  campagneItemList: Array<TCampagneItem>;
  filteredCampagneItemList: Array<TCampagneItem>;
  selectedId: string;
  campagneDetail: TCampagneDetail;
  loading: boolean;
  loadingDetail: boolean;
  loadingCreate: boolean;
}

const INITIAL_STATE = {
  campagneItemList: data,
  filteredCampagneItemList: data,
  selectedId: "",
  campagneDetail: {
    libelle: "",
    type: { sms: false, mail: false, whatsapp: false },
    description: "",
    message: "",
    file: {},
  },
  loading: false,
  loadingDetail: false,
  loadingCreate: false,
};

const campagneReducer = (
  state: ICampagneReducerState = INITIAL_STATE,
  action: CampagneAction
): ICampagneReducerState => {
  switch (action.type) {
    case campagneTypes.CAMPAGNE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case campagneTypes.CAMPAGNE_DETAIL_LOADING:
      return {
        ...state,
        loadingDetail: action.payload,
      };
    case campagneTypes.CAMPAGNE_CREATE_LOADING:
      return {
        ...state,
        loadingCreate: action.payload,
      };
    case campagneTypes.GET_ALL_CAMPAGNE:
      return {
        ...state,
        loading: false,
        campagneItemList: action.payload,
      };
    case campagneTypes.GET_SELECTED_CAMPAGNE:
      return {
        ...state,
        loadingDetail: false,
        campagneDetail: action.payload,
      };
    case campagneTypes.FILTER_SELECTED_CAMPAGNE:
      return {
        ...state,
        filteredCampagneItemList: action.payload,
      };
    case campagneTypes.SEARCH_CAMPAGNE:
      return {
        ...state,
        filteredCampagneItemList: action.payload,
      };
    case campagneTypes.SELECTED_ID:
      return {
        ...state,
        selectedId: action.payload,
      };
    default:
      return state;
  }
};

export default campagneReducer;
