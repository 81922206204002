import { SafeErrorBoundaries } from '@/components/SafeErrorBoundaries/SafeErrorBoundaries';
import { CAMPAGNE_PARAMS } from '@/configs/constants';
import { routePaths } from '@/routes/routesPath';
import { lazyImport } from '@/utils/lazyImport';
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import DashboardC from '../features/dashboard/Dashboard';
import { UserManage } from '../features/user/user-manage/user-manage';

const { CampagneCreate } = lazyImport(
  () => import("@/pages/userspace/features/campagne/campagne-create/CampagneCreate"),
  "CampagneCreate"
);

const { CampagneDetails } = lazyImport(
  () => import("@/pages/userspace/features/campagne/campagne-details/CampagneDetails"),
  "CampagneDetails"
);


export const CampagnesRoutes:React.FC = () => {
  return (
    <Routes>
      <Route path={routePaths.dashboard} element= {<SafeErrorBoundaries> <DashboardC /> </SafeErrorBoundaries>} />
        <Route path={routePaths.createCampagne} element= {<SafeErrorBoundaries> <CampagneCreate /> </SafeErrorBoundaries>} />
        <Route path={routePaths.detailsCampagne+'/:'+CAMPAGNE_PARAMS} element= { <CampagneDetails /> } />
        <Route path='' element= {<Navigate to={routePaths.dashboard} />}/>
    </Routes>
  )
}


export const UserSpaceRoutes:React.FC = () => {
  return (
    <Routes>
        <Route path={routePaths.userManage} element= {<SafeErrorBoundaries> <UserManage /> </SafeErrorBoundaries>} />
        {/* <Route path={routePaths.detailsCampagne+'/:'+CAMPAGNE_PARAMS} element= {<SafeErrorBoundaries>  <CampagneDetails /> </SafeErrorBoundaries> } /> */}
        <Route path='' element= {<Navigate to={routePaths.userManage} />}/>
    </Routes>
  )
}
