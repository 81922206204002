export enum authTypes {
    AUTH_LOADING = "AUTH_LOADING",
    AUTH_ERRORS = "AUTH_ERRORS",
    AUTHENTICATE = "AUTHENTICATE",
    GET_USER = "GET_USER",
    GET_USER_TOKEN = "GET_USER_TOKEN",
    REMEMBER_ME_AUTH = "REMEMBER_ME_AUTH",
    LOGOUT = "LOGOUT",
  }
  
export default authTypes;
  