// import { IAuthJwtPayload } from '@/interface';
import { IAuthJwtPayload, IAPIResponseInterface } from '@/interfaces';
import { apiCall } from '@/utils/api-call';
import { TSignIn } from '../types';

export const userAuthentificate = (data: TSignIn): Promise<IAPIResponseInterface> => {
  return apiCall.post('account/login', data);
};

export const  apiResetPasswordSendMail = ( email: any ):Promise<IAPIResponseInterface> => {
  return apiCall.post('/users/send-mail',email);
}

export const  apiResetPasswordByUserId = (endPoint:string,data:any ):Promise<IAPIResponseInterface> => {
  return apiCall.post(endPoint,data);
}
 