import { ReportAction } from "./report.actions";
import reportTypes from "./report.types";


interface IReportReducerState {
    missingColumns: Array<string>;
    phoneIssuesList:Array<{line:string,issue:string}>;
    emailIssuesList:Array<{line:string,issue:string}>;
    headerOfTheFile: Array<string>;
    loading:boolean;
    isFileGood:boolean;
}

const INITIAL_STATE = {
    missingColumns: [],
    phoneIssuesList: [],
    emailIssuesList: [],
    headerOfTheFile: [],
    loading: false,
    isFileGood: false,
}

const reportReducer = (
    state: IReportReducerState = INITIAL_STATE,
    action: ReportAction
):IReportReducerState => {

    switch (action.type) {
        case reportTypes.END_REPORT:
            return {
                ...state,
                loading: false,
            };

        case reportTypes.GET_MISSING_COLUMNS:
            return {
                ...state,
                loading: true,
                missingColumns: action.payload,
            };

        case reportTypes.GET_PHONE_ISSUES:
            return {
                ...state,
                loading: true,
                phoneIssuesList: action.payload,
            };

        case reportTypes.GET_MAIL_ISSUES:
            return {
                ...state,
                loading: true,
                emailIssuesList: action.payload,
            };

        case reportTypes.GET_HEADER:
            return {
                ...state,
                headerOfTheFile: action.payload,
            };

        case reportTypes.GET_FILE_STATUS:
            return {
                ...state,
                loading: true,
                isFileGood: action.payload,
            };
    
        default:
            return state;
    }

}

export default reportReducer;