import * as React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import GlobalStyle, { theme } from "@/theme";
import { store } from "@/store";
import mds from "@/assets/svgs/mds.svg";
import "./provider.scss";
import { CircularProgress } from "@mui/material";



type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="loading-screen">
          <img src={mds} alt="" />
          <span> Chargement </span>
          <CircularProgress />
        </div>
      }
    >
      <HelmetProvider>
        <ThemeProvider theme={theme}>
           <GlobalStyle />
            <Provider store={store}>
              <Router>
                    {children}
              </Router>
            </Provider>
        </ThemeProvider>
      </HelmetProvider>
    </React.Suspense>
  );
};
