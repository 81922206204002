import React, { useEffect, useState } from "react";
import "./user-manage.styles.scss";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  selectCampagneDetail,
  selectLoadingDetail,
} from "@/store/reducers/campagne/campagne.selector";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Button } from "@mui/material";
import { AddBox } from "@mui/icons-material";
import Panel from "@/components/UICs/Panel/Panel.uic";
import UserCreate from "../user-create/user-create";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber";
import { Icon } from "@iconify/react";
import { TUser } from "@/types";
import Swal from "sweetalert2";
import { ToastError, ToastGeneric } from "@/utils/toast";
import {
  changeUserStatus,
  deleteUser,
  getAllUsers,
  reinitPassword,
} from "@/pages/userspace/api/user.api";
import { CampagneDetailsSkeleton } from "@/pages/userspace/components/campagne-details-skeleton/CampagneDetailsSkeleton";
import { TableViewer } from "@/pages/userspace/components/table-viewer/TableViewer";
import { selectUserConnected } from "@/store/reducers/authentification/authentification.selector";
import { Loader } from "@/components/Loader/Loader";

type PropsFromRedux = ConnectedProps<typeof connector>;

const UserManageFunction: React.FC<PropsFromRedux> = ({
  loading,
  userConnected,
}) => {
  // const textColor = colors.text as TThemeColors;
  // const params = useParams();

  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<TUser>();
  const [userlist, setUserlist] = useState<TUser[]>([]);
  const [refreshUserList, setRefreshUserList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // Action du bouton pour la mise à jour et la suppression d'un utilisateur
  const checkIsForUpdateOrDelete = (
    e: React.MouseEvent<HTMLButtonElement>,
    user: TUser
  ) => {
    const currentTarget = e.target as HTMLButtonElement;

    // console.log("currentTarget ", currentTarget)

    if (currentTarget.id === "btn_update") {
      setOpenPanel(true);
      setUserSelected(user);
    }

    if (currentTarget.id === "btn_delete") {
    }

    if (currentTarget.id === "btn_locked") {
    }
  };

  // Confirmation suppression ou block
  const confirmActiveOrDeleteAccount = (
    idUser: string,
    isLocked?: boolean,
    isUnLocked?: boolean
  ) => {
    // console.log(" isLocked ",isLocked);
    // console.log(" isUnLocked ",isUnLocked);

    Swal.fire({
      title: isUnLocked
        ? "Désactivation de compte"
        : isLocked
        ? "Activation de compte"
        : " Suppression",
      text:
        "Vous êtes sur le point " +
        (isUnLocked
          ? "de désactiver un compte"
          : isLocked
          ? "d'activer un compte"
          : " supprimer un compte") +
        " désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#f2a252",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (isUnLocked || isLocked) {
          handleChangeUserStatus(isUnLocked, idUser);
        } else {
          handleDeletedUser(idUser);
        }
        setRefreshUserList(new Date().getTime.toString());
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const reinitiamlisationPassword = (idUser:string) => {
    Swal.fire({
      title:"Réinitialisation de mot de passe",
      text:
        "Vous êtes sur le point reinitialiser le mot de passe de l'utilisateur. "+
        " Désirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#f2a252",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        let response = await reinitPassword(idUser);

        setIsLoading(false);
        if(response?.error) {
          ToastGeneric(response.message, "error", 9000).fire();
          return;
        }

        ToastGeneric(response.message, "success", 9000).fire();

        // On rafraichir la liste des utilisateurs
        setRefreshUserList(new Date().getTime().toString());

      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "#",
      width: 60,
      hideSortIcons: true,
      disableColumnMenu: true,
    },
    {
      field: "nom",
      headerName: "Nom",
      width: 200,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.nom.toUpperCase()}`,
    },
    {
      field: "prenoms",
      headerName: "Prénom",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "profil_label",
      headerName: "Profil",
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: "labelIsActif",
      headerName: "Statut",
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: "telephone",
      headerName: "Téléphone",
      width: 200,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${formatPhoneNumber(params.row.telephone)}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <div className="btns-actions">
            {row.is_actif ? <> 
              <button
                type="button"
                id="btn_update"
                className="btn-edited"
                title="Modifier cet utilisateur"
                onClick={(e) => checkIsForUpdateOrDelete(e, row)}
              >
                <Icon icon="mdi:account-edit" />
              </button>
            
            </> : ""}
            {
            userConnected?.id !== row.id && row.is_actif ? <>
              
              <button
                type="button"
                id="btn_delete"
                className="btn-locked"
                title={
                  row.is_actif === 0
                    ? "Bloquer cet utilisateur"
                    : "Debloquer ce utilisateur"
                }
                onClick={(e) =>
                  confirmActiveOrDeleteAccount(
                    row.id,
                    row.is_actif === 0 ? true : false,
                    row.is_actif === 0 ? false : true
                  )
                }
              >
                {/* <Icon icon="mdi:account-lock" /> */}
                {row.is_actif === 0 && <Icon icon="mdi:account-lock" />}
                {row.is_actif === 1 && <Icon icon="mdi:account-lock-open" />}
              </button>
            
            </> : ""
            }
            {userConnected?.id !== row.id && (
              <button
                type="button"
                id="btn_locked"
                className="btn-deleted"
                title="Supprimer ce compte"
                onClick={(e) =>
                  confirmActiveOrDeleteAccount(row.id, false, false)
                }
              >
                <Icon icon="dashicons:trash" />
              </button>
            )}

            {userConnected?.id !== row.id && (
              <button
                type="button"
                id="btn-reinit"
                title="Reinitialiser"
                className="btn-reinit"
                onClick={(e) =>
                  reinitiamlisationPassword(row.id)
                }
              >
                <Icon icon="ic:baseline-refresh" />
              </button>
            )}
          </div>
        );
      }, //TODO : check params
    },
  ];

  // La liste des types d'acteurs
  const getUserList = async () => {
    const { data } = await getAllUsers();
    const dataWithNewObjectKeys: TUser[] = data.map((user: any, index: any) => {
      return {
        index: index + 1,
        id: user.id,
        nom: user.nom,
        prenoms: user.prenoms,
        email: user.email,
        profil_id: user.profil.id,
        profil_label: user.profil.libelle,
        telephone: user.telephone,
        labelIsActif: user.is_actif === 1 ? "Actif" : "Initialisation",
        is_actif: user.is_actif,
      };
    });
    setUserlist(dataWithNewObjectKeys);
  };

  useEffect(() => {
    getUserList();
  }, [refreshUserList]);

  const handleCreateUser = () => {
    let itemUser: TUser = {} as TUser;
    setUserSelected(itemUser);
    setOpenPanel(true);
  };

  const handleDeletedUser = async (idUser: string) => {

    setIsLoading(true);

    const response = await deleteUser(idUser!);
    const { error, message } = response;

    setIsLoading(false);

    if (error) {
      ToastError.fire({
        text: message,
        timer: 9000,
      });
      return;
    } else {
      ToastGeneric(
        `L'utiilsateur a été supprimé avec succès`,
        "success",
        5000
      ).fire();
    }
  };

  const handleChangeUserStatus = async (
    isUnLocked?: boolean,
    idUser?: string
  ) => {

    setIsLoading(true);

    const response = await changeUserStatus(
      { statut: isUnLocked ? 1 : 0 },
      idUser!
    );

    setIsLoading(false);

    const { error, message } = response;
    if (error) {
      ToastError.fire({
        text: message,
        timer: 9000,
      });
      return;
    } else {
      ToastGeneric(
        `Le status de l'utiilsateur a été changé avec succès`,
        "success",
        3000
      ).fire();
    }
  };
  return (
    <>
      {loading && (
        <>
          <CampagneDetailsSkeleton />
        </>
      )}
      {!loading && (
        <>
          <div className="user-details-layout ">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                  <div className="title-part">
                    <span className="title"> Gestion des utilisateurs</span>

                    <Button
                      className="add-btn"
                      type="submit"
                      variant="contained"
                      color="warning"
                      endIcon={<AddBox />}
                      onClick={(e) => handleCreateUser()}
                    >
                      Nouvel utilisateur
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12}>
                  <div className="table-container">
                    <Loader isLoading={isLoading} />
                    <TableViewer columns={columns} rows={userlist} />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </>
      )}

      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Création d'un utilisateur"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <UserCreate
          refreshUserList={setRefreshUserList}
          user={userSelected}
          closeSidePanel={setOpenPanel}
        />
      </Panel>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoadingDetail,
  campagneDetail: selectCampagneDetail,
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);

export const UserManage = connector(UserManageFunction);
