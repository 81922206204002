import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import styled, { css } from "styled-components";
import ReactPortal from "../../HOCs/ReactPortal.hoc";

const ENTERING = 1;
const VISIBLE = 2;
const LEAVING = 3;
const HIDDEN = 4;
const PanelTransitionTime = 300;

type TPanelProps = React.HTMLAttributes<HTMLDivElement> & {
  toggleOpen: boolean;
  openWidth?: string;
  minWidth?: string;
  closeSidePanel: () => void;
  hasBackDrop?: boolean;
  position?: "right" | "left";
  title: string;
};

const Panel: React.FC<TPanelProps> = ({
  toggleOpen = true,
  openWidth = "430px",
  minWidth,
  closeSidePanel,
  hasBackDrop = false,
  position = "right",
  title,
  children,
  ...props
}) => {
  const [state, setState] = useState<number>(toggleOpen ? ENTERING : HIDDEN);
  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (toggleOpen) {
      setState(ENTERING);
    } else if (state !== HIDDEN) setState(LEAVING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleOpen]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (state === ENTERING) {
      timer = setTimeout(() => {
        setState(VISIBLE);
      }, 1);
    } else if (state === LEAVING) {
      timer = setTimeout(() => {
        setState(HIDDEN);
      }, PanelTransitionTime + 10);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  if (state === HIDDEN) return <></>;

  return (
    <ReactPortal wrapperId="panel-wrapper">
      <PanelContainer
        ref={panelRef}
        position={position}
        hasBackDrop={hasBackDrop}
        open={toggleOpen}
        {...props}
      >
        {hasBackDrop && <PanelOverlay state={state} onClick={closeSidePanel} />}
        <PanelContent
          position={position}
          width={openWidth}
          minWidth={minWidth ? minWidth : openWidth}
          state={state}
        >
          <PanelContentHeader>
            <PanelTitle>{title}</PanelTitle>
            <IconButton onClick={closeSidePanel}>
              <Icon
                icon="clarity:window-close-line"
                fontSize={36}
              />
            </IconButton>
          </PanelContentHeader>
          <PanelChildren>{children}</PanelChildren>
        </PanelContent>
      </PanelContainer>
    </ReactPortal>
  );
};

export default Panel;

const PanelContainer = styled.div<{
  position: "right" | "left";
  open: boolean;
  hasBackDrop: boolean;
}>`
  top: 0;
  ${(props) => props.position}: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 501;

  ${(props) =>
    props.open &&
    css`
      pointer-events: all;
    `}

  ${(props) =>
    !props.hasBackDrop &&
    css`
      width: 430px;
    `}
`;

const PanelOverlay = styled.div<{ state: number }>`
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: 501;
  transition: opacity ${PanelTransitionTime}ms cubic-bezier(0, 0.55, 0.45, 1);

  ${(props) => {
    //meaning the panel is in "VISIBLE state"
    if (props.state === 2) {
      return css`
        cursor: pointer;
        opacity: 0.1;
      `;
    }
  }}
`;

const PanelContent = styled.div<{
  position: "right" | "left";
  width: string;
  minWidth: string;
  state: number;
}>`
  position: fixed;
  top: 0;
  ${(props) => props.position}: calc(-${(props) => props.width} - 3px);
  width: ${(props) => props.width};
  min-width: ${({ minWidth }) => minWidth};
  height: 100%;
  background-color: #fff;
  z-index: 502;
  padding: 24px 20px;
  transition: ${(props) => props.position} ${PanelTransitionTime}ms
    cubic-bezier(0, 0.55, 0.45, 1);
  box-shadow: -4px 0px 64px rgba(0, 0, 0, 0.07);

  ${(props) => {
    //meaning the panel is in "VISIBLE state"
    if (props.state === 2) {
      return css`
        ${props.position}: 0;
      `;
    }
  }}
`;

const PanelTitle = styled.h3`
  font-weight: bold;
  font-size: 18px;
  color: #000000;
  margin-right: 15px;
  margin-bottom: 0;
`;

const PanelContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const PanelChildren = styled.div`
  height: calc(100vh - 86px);
  overflow-y: auto;
  padding-bottom: 20px;
`;

const IconButtonTransitionTime = 100;
const IconButton = styled.span`
  cursor: pointer;
  color: #000;
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -5px;
  z-index: 0;
  transition: color ${IconButtonTransitionTime}ms ease-in-out;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    background-color: #000;
    opacity: 0.5;
    transform: scale(0);
    transition: transform ${IconButtonTransitionTime}ms ease-in-out;
    z-index: -1;
  }

  &:hover {
    color: white;
    &::before {
      transform: scale(1.2);
    }
  }
`; 
