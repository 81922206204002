import React, { useState } from "react";
import "./CampagneItem.styles.scss";
import classnames from "classnames";
import sms from "@/assets/svgs/sms.svg";
import mail from "@/assets/svgs/mail.svg";
import whatsapp from "@/assets/svgs/whatsapp.svg";
import { TCampagneItem } from "@/types";
import { routePaths } from "@/routes/routesPath";
import { useNavigate } from "react-router-dom";
import {
  selectFilteredCampagneItemList,
  selectSelectedId,
} from "@/store/reducers/campagne/campagne.selector";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  filterSelectedCampagne,
  setSelectedId,
} from "@/store/reducers/campagne/campagne.actions";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { boolean } from "yup";
import { store } from "@/store";

type PropsFromRedux = ConnectedProps<typeof connector>;
type CampagneItemProps = PropsFromRedux & {
  item: TCampagneItem;
  idCampagneSelected?:string,
  setCampagneSelectId?: React.Dispatch<React.SetStateAction<string>> | undefined
};

const CampagneItem: React.FC<CampagneItemProps> = ({
  item,
  filteredCampagneItemList,
  idCampagneSelected,
  setCampagneSelectId
  // filterSelectedCampagne,
}) => {
  const navigate = useNavigate();
  const goTo = (route: string = "/my-space") => {
    if(setCampagneSelectId){
      setCampagneSelectId(item?.id);
    }
    
    navigate(`${route}/${item?.id}`);
    store.dispatch(filterSelectedCampagne(filteredCampagneItemList, item.id));
    setSelectedId(item.id);
    // item.isActive = false;
  };

  // const [isActiveItem, setIsActiveItem] = useState<boolean>(false);

  // const activeItem = (itemId: string) => {
  //   if (item.id.toString() !== itemId) {
  //     if (isActiveItem) {
  //       setIsActiveItem(false);
  //     } else {
  //       setIsActiveItem(true);
  //     }
  //   }
  // };

  // console.log("Les campagnes: ", item);

  return (
    <>
      <div
        className={classnames("campagne-item", { active: item?.id === idCampagneSelected })}
        onClick={() => {
          goTo(routePaths.detailsCampagne);
          // activeItem(item?.id.toString());
        }}
      >
        <span className="campagne-title" title={item.libelle}>
          {" "}
          {item?.libelle}{" "}
        </span>
        <div className="campagne-type">
          <img
            src={sms}
            className={classnames({ "active-sms": item?.type_campagne?.sms })}
            alt=""
          />
          <img
            src={mail}
            className={classnames({ "active-mail": item?.type_campagne?.mail })}
            alt=""
          />
          <img
            src={whatsapp}
            className={classnames({ "active-whatsapp": item?.type_campagne?.whatsapp })}
            alt=""
          />
        </div>
        <p className="campagne-description">{item?.description}</p>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  filteredCampagneItemList: selectFilteredCampagneItemList,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  // filterSelectedCampagne: (campagneList: Array<TCampagneItem>, id: number) =>
  //   dispatch(filterSelectedCampagne(campagneList, id)),
  setSelectedId: (selecedId: string) => dispatch(setSelectedId(selecedId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(CampagneItem);
