import { createGlobalStyle } from "styled-components";
import convertThemeColorsToRootColors from "../utils/convertThemeColorsToRootColors";



const GlobalStyle = createGlobalStyle`

    *, ::after, ::before {
        box-sizing: border-box;
        /* font-family: 'Open Sans', sans-serif; */
        font-family: 'azo-sans-web', sans-serif;
        padding: 0;
        margin: 0;
    }

    :root {
        ${({ theme }) => {
          return convertThemeColorsToRootColors(theme.colors);
        }}
        --safe-padding: 100px;
        --safe-padding-small: 20px;

        --img-filter-inactive-dark: invert(62%) sepia(0%) saturate(0%) hue-rotate(237deg) brightness(91%) contrast(86%);
        --img-filter-inactive-light: invert(99%) sepia(89%) saturate(127%) hue-rotate(190deg) brightness(110%) contrast(88%);
        --img-filter-sms: invert(50%) sepia(53%) saturate(1863%) hue-rotate(345deg) brightness(101%) contrast(91%);
        --img-filter-mail: invert(71%) sepia(66%) saturate(4920%) hue-rotate(176deg) brightness(90%) contrast(89%);
        --img-filter-whatsapp: invert(71%) sepia(66%) saturate(426%) hue-rotate(71deg) brightness(83%) contrast(98%);
    }
`;

export default GlobalStyle;
