import React from 'react';
import "./TableViewer.styles.scss";
import LinearProgress from '@mui/material/LinearProgress';
import {    DataGrid, 
            GridColDef, 
            GridValueGetterParams, 
            GridToolbarContainer,
            GridToolbarFilterButton,
            GridToolbarColumnsButton,
            GridToolbarExport,
         } from '@mui/x-data-grid';


type TTableViewerProps = {
    columns: any[],
    rows: any[],
}


const CustomToolbar: React.FunctionComponent<{
    setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    setColumnButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    setExportButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    }> = ({ setFilterButtonEl,setColumnButtonEl,setExportButtonEl }) => (

    <GridToolbarContainer className='table-toolbar-container'>
        <GridToolbarFilterButton className='toolbar-filter' ref={setFilterButtonEl} nonce={undefined} onResize={undefined} onResizeCapture={undefined}  />
        <GridToolbarColumnsButton className='toolbar-column' ref={setColumnButtonEl} nonce={undefined} onResize={undefined} onResizeCapture={undefined}  />
        <GridToolbarExport className='toolbar-export' ref={setExportButtonEl} />
    </GridToolbarContainer>
);
              

// const formatPhoneNumber = (str: string) => {
//     //Filter only numbers from the input
//     let cleaned = ('' + str).replace(/\D/g, '');
    
//     //Check if the input is of correct length
//     let match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);
  
//     if (match) {
//       return '' + match[1] + ' - ' + match[2] + ' - ' + match[3] + ' - ' + match[4] + ' - ' + match[5]
//     };
  
//     return null
// };

// const columns: GridColDef[] = [
//     { field: 'id', headerName: '#', width: 50, hideSortIcons: true,disableColumnMenu:true },
//     { field: 'noms', headerName: 'Nom', width: 150, disableColumnMenu:true, valueGetter: (params: GridValueGetterParams) => `${params.row.noms.toUpperCase()}` },
//     { field: 'prenoms', headerName: 'Prénom', width: 150, disableColumnMenu:true  },
//     { field: 'email', headerName: 'Email', width: 250, disableColumnMenu:true  },
//     { field: 'telephone', headerName: 'Téléphone', width: 150, disableColumnMenu:true, valueGetter: (params: GridValueGetterParams) => `${ formatPhoneNumber(params.row.telephone)  }` },
//     { field: 'message', headerName: 'Message', width: 300, disableColumnMenu:true  },
// ];

// const rows = [
//     { id: 1, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 2, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 3, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 4, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 5, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 6, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 7, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 8, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 9, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 10, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 11, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 12, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
//     { id: 13, noms: 'YAMB', prenoms: 'Etienne Landry', email: 'landryyamb446@gmail.com', telephone: '0151241826', message:" Mr/Mme [NOM], merci de de recevoir ci-joint le bulletin de votre enfant [NOM_ENFANT] pour compte du premier trimestre " },
// ];
  

export const  TableViewer:React.FC<TTableViewerProps> = ({columns, rows}) => {


    const [filterButtonEl, setFilterButtonEl] = React.useState<HTMLButtonElement | null>(null);
    const [columnButtonEl, setColumnButtonEl] = React.useState<HTMLButtonElement | null>(null);
    const [exportButtonEl, setExportButtonEl] = React.useState<HTMLButtonElement | null>(null);


    return (
        <div className="table-viewer-layout">
            <DataGrid
                columns={columns}
                rows={rows}
                rowHeight={50}
                // rowsPerPageOptions={[5,10,15]}
                pageSize={10}
                // checkboxSelection = {true}
                autoHeight={true}
                loading={false}
                localeText={{
                    toolbarFilters:'Filtre',
                    toolbarFiltersLabel:'Filtre',
                    toolbarColumns:'Colonnes',
                    toolbarColumnsLabel:'Colonnes',
                    toolbarExportCSV:'Exporter en CSV',
                    toolbarExportPrint:'Imprimer',
                    filterOperatorContains: 'contient',
                    filterOperatorEquals: 'égale à',
                    filterOperatorStartsWith: 'commence par',
                    filterOperatorEndsWith: 'fini par',
                    filterOperatorIsEmpty: 'est vide',
                    filterOperatorIsNotEmpty: "n'est pas vide",
                    filterOperatorIsAnyOf: 'appartient à',
                    filterPanelColumns:'Colonne',
                    filterPanelOperators:'Opération',
                    filterPanelInputLabel:'Valeur',
                    filterPanelInputPlaceholder: 'valeur filtrée',
                    columnsPanelHideAllButton:'Cacher tout',
                    columnsPanelShowAllButton:'Afficher tout',
                    columnsPanelTextFieldPlaceholder:'colonne ...',
                    columnsPanelTextFieldLabel:'Recherche colonne',
                    checkboxSelectionHeaderName:'Case(s) à cocher',
                    noResultsOverlayLabel: 'Aucun résultat.',
                    footerRowSelected: (count: number) => `${count} lignes sélectionnées`,

                }}

                components={{
                    Toolbar: CustomToolbar,
                    LoadingOverlay: LinearProgress,
                }}

                componentsProps={{
                    panel: {
                        anchorEl: filterButtonEl,
                        columnButtonEl: columnButtonEl,
                        exportButtonEl: exportButtonEl,
                    },
                    toolbar: {
                        setFilterButtonEl,
                        setColumnButtonEl,
                        setExportButtonEl,
                    },
                }}
            />
        </div>
    )
}
