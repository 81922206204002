import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authTypes from "./auth/auth.types";

import authReducer from "./auth/auth.reducer";
import reportReducer from "./report/report.reducer";
import campagneReducer from "./campagne/campagne.reducer";
import userReducer from './authentification/authentification.reducer'

export const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth" , "user"],
  // whitelist: ["auth"],
  blacklist: [],
};

const topReducer = combineReducers({
  auth: authReducer,
  report: reportReducer,
  campagne: campagneReducer,
  user : userReducer
});

const rootReducer = (state: any, action: any) => {
  // when RESET action is dispatched it will reset redux state
  if (action.type === authTypes.LOGOUT) {
    state = undefined;
  }
  return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
