import { TThemeColors } from "@/types";

export const colors: TThemeColors = {
  text: {
    primary: "#2997DB",
    secondary : "#F37327", //#8A93FF
    dark: "#0a0909",
    'dark-tint': "#5d5656",
    medium: "#8E8E8E",
    'green-whatsapp': "#4CC959",
    white: "#ffffff",
    danger: "#D32F2F",
    'danger-tint': "#fadddd",
    success: "#5cb85C",
  },
  bg: {
    primary: "#2997DB",
    secondary: "#F3732A",//#8A93FF
    'secondary-light':"#fff1e9",
    white:"#ffffff",
    light: "#F5F5F5",
    'light-shade':"#DEDEDE",
    dark: "#0a0909",
    'dark-tint': "#5d5656",
    danger: "#D32F2F",
    'danger-tint': "#fadddd",
    success: "#5cb85C",
  }
};
