import { store } from "@/store";
import {
  getAllCampagne,
  getCampagneDetail,
  setLoadingCampagne,
  setLoadingCampagneCreate,
} from "@/store/reducers/campagne/campagne.actions";
import {
  TCampagne,
  TCampagneDetail,
  TCampagneItem,
  TCredentials,
} from "@/types";
import { apiCall } from "@/utils/api-call";
import { isDev } from "@/utils/isDev";
import { ToastGeneric } from "@/utils/toast";
import { useNavigate } from "react-router-dom";

export class CampagneService {
  static createCampagne(campagne: TCampagne) {
    const { libelle, type, description, message, file, attach_file } = campagne;

    console.log("0000000", campagne);

    let formData = new FormData();
    formData.append("libelle", libelle);
    formData.append("type", JSON.stringify(type));
    formData.append("description", description);
    formData.append("message", message);
    formData.append("file", file as File);
    formData.append("attach_file", attach_file as File);

    apiCall
      .post("/campagne/campaign", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response: any) => {
        console.log(response);
        if (response.error) {
          ToastGeneric(
            "Une erreur est survenue lors de la création de votre campagne!",
            "error",
            3000
          ).fire();
        } else {
          ToastGeneric(
            "Votre campagne a été créée avec succès!",
            "success",
            3000
          ).fire();

          console.log(" je fais le reload ");
          
          setTimeout(() => {
            window.location.reload();
          }, 1300);
         
        }
        store.dispatch(setLoadingCampagneCreate(false));
        return response;
      })
      .catch((error) => {
        if (isDev()) {
          console.error(error);
        }
        store.dispatch(setLoadingCampagneCreate(false));
        ToastGeneric("Erreur serveur!", "error", 3000).fire();
      });
  }

  static getCampagneItemList() {
    apiCall
      .get("/campagne/campaign")
      .then((response: any) => {
        if (response.error) {
          ToastGeneric(
            "Une erreur est survenue lors de la récupération de la liste de vos campagnes!",
            "error",
            3000
          ).fire();
        } else {
          store.dispatch(getAllCampagne(response.data as TCampagneItem[]));
        }
      })
      .catch((error) => {
        if (isDev()) {
          console.error(error);
        }
        ToastGeneric("Erreur serveur!", "error", 3000).fire();
      });
  }

  static getSelectedCampagne(id: string) {
    apiCall
      .get(`/campagne/${id}`)
      .then((response: any) => {
        // ,{params: {id}}
        if (response.error) {
          ToastGeneric(
            "Une erreur est survenue lors de la récupération du détail de votre campagne sélectionnée!",
            "error",
            3000
          ).fire();
        } else {
          store.dispatch(getCampagneDetail(response.data as TCampagneDetail));
        }
      })
      .catch((error) => {
        if (isDev()) {
          console.error(error);
        }
        ToastGeneric("Erreur serveur!", "error", 3000).fire();
      });
  }
}
