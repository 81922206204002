export const routePaths = {
  welcome: "/welcome",
  login: "/login",
  signin: "/connexion",
  signup: "inscription",
  forgotten: "pass-oublie",
  userspace: "/my-space",
  dashboard: "dashboard",
  createCampagne: "create-campagne",
  detailsCampagne: "details-campagne",
  userManage: "user-manage",

  welcomes: {
    path: "/welcome",
  },
  auth: {
    path: "/auth",
    children: {
      signin: "/signin",
      signup: "/signup",
      forgotten: "/forgotten",
    },
  },
  userSpace: {
    path: "/my-space",
    children: {
      campagne: {
        path: "/campagne",
        children: {
          dashboard: "dashboard",
          createCampagne: "create-campagne",
          detailsCampagne: "details-campagne",
        },
      },
      user: {
        path: "/user",
      },
    },
  },
};

interface IRouteNode {
  path: string;
  children: {
    [key: string]: string;
  };
}

export const getAbsolutePath = (
  routeNode: IRouteNode,
  child: string
): string => {
  let result = "";
  result = routeNode.path + child;
  return result;
};
