import React from 'react';
import './CampagneDetailsSkeleton.styles.scss';
import {Box, Grid, Skeleton} from '@mui/material';

export const CampagneDetailsSkeleton: React.FC = () => {

  return (
      <div className="campagne-details-layout-skeleton ">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={4} >
                  <div className="item-left">
                    <Skeleton animation="wave" variant="text" className="title" />
                    <Skeleton animation="wave" variant="text" className="date" />
                    <Skeleton animation="wave" variant="rectangular" className="description" />
                  </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={8} >
                  <div className="item-right">
                    <div className="chart-container">
                        <div className="chart-layout">
                          <Skeleton animation="pulse" variant="circular" className="circ" />
                        </div>
                        <Skeleton animation="wave" variant="text" className="small" />
                    </div>
                    <div className="chart-container">
                        <div className="chart-layout">
                          <Skeleton animation="pulse" variant="circular" className="circ" />
                        </div>
                        <Skeleton animation="wave" variant="text" className="small" />
                    </div>
                    <div className="chart-container">
                        <div className="chart-layout">
                          <Skeleton animation="pulse" variant="circular" className="circ" />
                        </div>
                        <Skeleton animation="wave" variant="text" className="small" />
                    </div>
                  </div>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12} >
                <div className="table-container">
                  <Skeleton animation="pulse" variant="rectangular" className="row-skeleton" />
                  <Skeleton animation="wave" variant="rectangular" className="row-skeleton" />
                  <Skeleton animation="wave" variant="rectangular" className="row-skeleton" />
                </div>
              </Grid>
            </Grid>
          </Box>
      </div>
  )
}
