import React, { useEffect, useState } from "react";
import "./Sidebar.styles.scss";
import CampagneItem from "../campagne-item/CampagneItem";
import { Button } from "@mui/material";
import searchIcon from "@/assets/images/search_icon.png";
import chatIcon from "@/assets/svgs/comment-alt-plus.svg";
import filterIcon from "@/assets/svgs/filter-solid.svg";
import { TCampagneItem } from "@/types";
import { NavLink, useNavigate } from "react-router-dom";
import { routePaths } from "@/routes/routesPath";
import { createStructuredSelector } from "reselect";
import { connect, ConnectedProps } from "react-redux";
import {
  selectCampagneItemList,
  selectFilteredCampagneItemList,
  selectLoading,
  selectSelectedId,
} from "@/store/reducers/campagne/campagne.selector";
import { CampagneItemSkeleton } from "../campagne-item-skeleton/CampagneItemSkeleton";
import { store } from "@/store";
import {
  filterSelectedCampagne,
  getAllCampagne,
  searchCampagne,
  setSelectedId,
} from "@/store/reducers/campagne/campagne.actions";
import { Icon } from "@iconify/react";
import { CampagneService } from "@/services/campagne.service";
import { ToastGeneric } from "@/utils/toast";
import { apiCall } from "@/utils/api-call";
import { setAuthToken } from "@/utils/setAuthToken";

type PropsFromRedux = ConnectedProps<typeof connector>;

const Sidebar: React.FC<PropsFromRedux> = ({
  loading,
  campagneItemList,
  filteredCampagneItemList,
  selectedId,
}) => {
  const navigate = useNavigate();
  //const [campagneItemList, setCampagneItemList] = useState<TCampagneItem[]>();

  const [campagneSelectId, setCampagneSelectId] = useState('');
  const getListeCampagnes = () => {
    apiCall
      .get("/campagne/campaign")
      .then((response: any) => {
        console.log(" response compaign ", response);

        if (response.error) {
          ToastGeneric(
            "Une erreur est survenue lors de la récupération de la liste de vos campagnes!",
            "error",
            3000
          ).fire();
        } else {
          if (response && response.data) {
            console.log(" response.data", response.data);
            store.dispatch(getAllCampagne(response.data as TCampagneItem[]));

            //setCampagneItemList(response.data as TCampagneItem[]);
          }
        }
      })
      .catch((error) => {
        ToastGeneric("Erreur serveur!", "error", 3000).fire();
      });
  };

  useEffect(() => {
    /** A SUPPRIMER ASTUCE PR L'HEURE POUR EVITER LA PERTE DE AUTH */
    let token = sessionStorage.getItem("token");
    if (token) setAuthToken(token);
    /** FIN  */

    getListeCampagnes();
  }, []);

  // const goTo = (route: string = "/my-space") => {
  //   navigate(`${route}`);
  //   store.dispatch(filterSelectedCampagne(campagneItemList,-1));
  //   store.dispatch(setSelectedId(-1));
  // };

  const searchOnKeyUp = () => {
    let inputValue = (
      document.getElementById("searchValue") as HTMLInputElement
    )?.value;
    store.dispatch(searchCampagne(campagneItemList, inputValue));
    store.dispatch(
      filterSelectedCampagne(filteredCampagneItemList, selectedId)
    );
  };

  return (
    <div className="side-container">
      <div className="side-header">
        <div className="sidebar-nav">
          <NavLink to={routePaths.dashboard} className="sidebar btn-home">
            <Icon className="icon" icon="iconoir:home-alt" />
          </NavLink>
          <NavLink to={routePaths.createCampagne} className="sidebar btn-add">
            <span> Nouvelle campagne</span>
            <Icon className="icon" icon="iconoir:chat-add" />
          </NavLink>
        </div>
        <div className="side-filter" style={{ display: "none" }}>
          <img src={filterIcon} className="search-icon" alt="" />
          <select name="" id="">
            <option value="">Récent</option>
            <option value="">Depuis une semaine</option>
            <option value="">Depuis un mois</option>
          </select>
        </div>

        {/* <div className="side-search">
          <img src={searchIcon} className="search-icon" alt="" />
          <input
            type="text"
            name=""
            id="searchValue"
            placeholder="Recherche ..."
            onKeyUp={searchOnKeyUp}
          />
        </div> */}
      </div>
      {loading && (
        <>
          <CampagneItemSkeleton />
        </>
      )}
      {!loading && (
        <>
          <small>
            {" "}
            <b>
              {campagneItemList && campagneItemList.length === 0
                ? "Aucune"
                : campagneItemList && campagneItemList.length}
            </b>
            &nbsp;Campagne(s){" "}
          </small>
          <div className="content-body">
            {campagneItemList &&
              campagneItemList.map((x: TCampagneItem, index) => (
                <CampagneItem key={index} item={x} setCampagneSelectId={setCampagneSelectId} idCampagneSelected={campagneSelectId} />
              ))}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectLoading,
  campagneItemList: selectCampagneItemList,
  filteredCampagneItemList: selectFilteredCampagneItemList,
  selectedId: selectSelectedId,
});

const connector = connect(mapStateToProps);

export default connector(Sidebar);
