import { SafeErrorBoundaries } from "@/components/SafeErrorBoundaries/SafeErrorBoundaries";
import { Navigate } from "react-router-dom";
import { lazyImport } from "../utils/lazyImport";
import { routePaths } from "./routesPath";


const { Welcome } = lazyImport(
  () => import("@/pages/welcome/Welcome"),
  "Welcome"
);

const { Login } = lazyImport(
  () => import("@/pages/login/Login"),
  "Login"
);



export const publicRoutes = [
  {
    path: routePaths.welcome,
    element:
      <SafeErrorBoundaries> 
        <Welcome />
      </SafeErrorBoundaries>,
  },
  {
    path: routePaths.login + "/*",
    element: <Login />
  },
 
  { path: "*", element: <Navigate to="/login" /> }
];
