import Axios from "axios";

import { setAuthToken } from "@/utils/setAuthToken";

import { store } from "@/store";
import { logout } from "@/store/reducers/auth/auth.actions";

import { API_URL } from "@/configs/constants";

export const apiCall = Axios.create({
  baseURL: API_URL,
});

apiCall.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;

    console.log("message erreur ",message); 

    if (error && error.response &&  error.response.status === 401) {
      // UNAUTORIZED REQUEST ==> LOGOUT AUTOMATICALLY THE CURRENT CONNEXION
      setAuthToken(null);
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

// apiCall.interceptors.request.use( (req : any) => {
//   if (req.data) {
//     /** En modification la donnée est envoyé en formData */
//     req.data = {
//       ...req.data
//     };
//   }
//   return req;
// });


