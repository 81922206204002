import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import { isDev } from "@/utils/isDev";

import rootReducer from "./reducers/rootReducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [
  thunk,
  createLogger({
    predicate: () => isDev(),
    collapsed: true,
  }),
];

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
