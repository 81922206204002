import { apiCall } from "./api-call";

export const setAuthToken = (token: string | null) => {

  var newToken = token; // Je procede ainsi pr eviter la redirection en attendant de comprendre

  if(!token){
    // En ce moment on verifie si il n'y a pas de valeur dans ession sotrage
    newToken = sessionStorage.getItem("token");
  } 

  if (newToken) {
    apiCall.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
  } else {
    delete apiCall.defaults.headers.common["Authorization"];
  }
};

