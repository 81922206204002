
import  UserSpace  from "@/pages/userspace/UserSpace";
import { Navigate } from "react-router-dom";
import { routePaths } from "./routesPath";
// import { lazyImport } from "../utils/lazyImport";
// import { routePaths } from "./routesPath";

// const { Accueil } = lazyImport(
//   () => import("@/pages/accueil/Accueil"),
//   "Accueil"
// );

export const protectedRoutes = [
  {
    path: routePaths.userspace + "/*",
    element: 
      <UserSpace /> ,
  },
  { path: "*", element: <Navigate to="/my-space" /> }
  
];
