import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useState } from "react";
import "./Dashboard.styles.scss";
import { PieChart, Pie, Sector } from "recharts";
import { TCampagneStatistique } from "@/types";
import { CampagneService } from "@/services/campagne.service";
import { apiCall } from "@/utils/api-call";
import { ToastGeneric } from "@/utils/toast";

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export const DashboardC: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [dataStatistique, setDataStatistique] =
    useState<TCampagneStatistique>();

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  // On recupere la lsite des statistiques
  const getStatistique = () => {
    apiCall
      .get("/campagne/statistiques")
      .then((response: any) => {
        if (response.error) {
          ToastGeneric(
            "Une erreur est survenue lors de la récupération de la liste de vos campagnes!",
            "error",
            3000
          ).fire();
        } else {
          if (response && response.data) {
            setDataStatistique(response.data as TCampagneStatistique);
          }
        }
      })
      .catch((error) => {
        ToastGeneric("Erreur serveur!", "error", 3000).fire();
      });
  };

  useEffect(() => {
    getStatistique();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="title">
        <span>Tableau de bord </span>
      </div>

      <div className="stat-container">
        <div className="stat-item color-red">
          <div className="stat-header">
            <Icon className="stat-icon" icon="ph:presentation-chart" />
            <span>Total campagne</span>
          </div>
          <div className="stat-body">
            <span className="value">
              {dataStatistique?.total_campagne || 0}
            </span>
          </div>
          {/* <div className="stat-footer">
            <button>Voir plus</button>
          </div> */}
        </div>
        <div className="stat-item color-orange">
          <div className="stat-header">
            <Icon className="stat-icon" icon="la:sms" />
            <span>SMS envoyé</span>
          </div>
          <div className="stat-body">
            <span className="value">{dataStatistique?.total_sms || 0}</span>
          </div>
          {/* <div className="stat-footer">
            <button>Voir plus</button>
          </div> */}
        </div>
        <div className="stat-item color-blue">
          <div className="stat-header">
            <Icon className="stat-icon" icon="ic:round-mail-outline" />
            <span>Mail envoyé</span>
          </div>
          <div className="stat-body">
            <span className="value">{dataStatistique?.total_mail || 0}</span>
          </div>
          {/* <div className="stat-footer">
            <button>Voir plus</button>
          </div> */}
        </div>
        <div className="stat-item color-purple">
          <div className="stat-header">
            <Icon className="stat-icon" icon="fontisto:whatsapp" />
            <span>Whatsapp envoyé</span>
          </div>
          <div className="stat-body">
            <span className="value">
              {dataStatistique?.total_whatsapp || 0}
            </span>
          </div>
          {/* <div className="stat-footer">
            <button>Voir plus</button>
          </div> */}
        </div>
      </div>

      <div className="pie-chart-container">
        <div className="chart-item">
          <PieChart width={400} height={400}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx={200}
              cy={200}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
        </div>
        <div className="chart-item">
          <PieChart width={400} height={400}>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx={200}
              cy={200}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
        </div>
      </div>
    </div>
  );
};

export default DashboardC;
