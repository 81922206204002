import { RootState } from "@/store";
import { createSelector } from "reselect";

export const selectCampagne = (state: RootState) => state.campagne;

export const selectLoading = createSelector(
  [selectCampagne],
  (campagne) => campagne.loading
);

export const selectLoadingDetail = createSelector(
  [selectCampagne],
  (campagne) => campagne.loadingDetail
);

export const selectLoadingCreate = createSelector(
  [selectCampagne],
  (campagne) => campagne.loadingCreate
);

export const selectCampagneDetail = createSelector(
    [selectCampagne],
    (campagne) => campagne.campagneDetail
);

export const selectCampagneItemList = createSelector(
    [selectCampagne],
    (campagne) => campagne.campagneItemList
);

export const selectFilteredCampagneItemList = createSelector(
  [selectCampagne],
  (campagne) => campagne.filteredCampagneItemList
);

export const selectSelectedId = createSelector(
  [selectCampagne],
  (campagne) => campagne.selectedId
);