import {
  selectAuthToken,
} from "@/store/reducers/auth/auth.selector";
import { selectUserConnected } from "@/store/reducers/authentification/authentification.selector";
import { setAuthToken } from "@/utils/setAuthToken";
import { connect, ConnectedProps } from "react-redux";
import { Navigate, useRoutes } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { protectedRoutes } from "./protected.routes";
import { publicRoutes } from "./public.routes";

type PropsFromRedux = ConnectedProps<typeof connector>;

type AppRoutesProps = PropsFromRedux & {
  userToken?: string;
};

const AppRoutes: React.FC<AppRoutesProps> = ({ userConnected }) => {
  
  // setAuthToken(userToken);

  // const commonRoutes = [{ path: "*", element: <Navigate to="/welcome" /> }];

  //const routes = auth ? protectedRoutes : publicRoutes;
  // const routes = publicRoutes;
//console.log(routes);
  // const element = useRoutes([...routes, ...commonRoutes]);
  const routes = userConnected ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes]);
  return <>{element}</>;
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);
export default connector(AppRoutes);
