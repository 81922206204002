// import { IAuthJwtPayload } from '@/interface';
import { IAPIResponseInterface } from '@/interfaces';
import { TUser } from '@/types';
import { apiCall } from '@/utils/api-call';

// export const createUser = (data: TUser): Promise<IAPIResponseInterface> => {
//   return apiCall.post('account/login', data);
// };

export const  apiChangePassword = (data:any,idUser:string ):Promise<IAPIResponseInterface> => {
    return apiCall.put('account/reset-password/'+idUser,data);
  }

export const  apiSendMailForResetPassword = (data:any ):Promise<IAPIResponseInterface> => {
  return apiCall.post('account/reset-password',data);
}

export const createUser = (data: TUser): Promise<IAPIResponseInterface> => {
    const toSend = {
        ...data,
    };
    return apiCall.post('/account/accounts', toSend);
};

export const updateUser = (data: TUser, idUser: string): Promise<IAPIResponseInterface> => {
    const toSend = {
        ...data,
    };
    return apiCall.put(`/account/accounts/${idUser}`, toSend);
};
  
export const getAllUsers = (): Promise<IAPIResponseInterface> => {
    return apiCall.get('/account/accounts');
};

export const deleteUser = (idUser: string): Promise<IAPIResponseInterface> => {
    return apiCall.delete(`/account/accounts/${idUser}`);
};

export const changeUserStatus = (data: any, idUser: string): Promise<IAPIResponseInterface> => {
    const toSend = {
        ...data,
    };
    return apiCall.put(`/account/update-statut/${idUser}`, toSend);
};

export const reinitPassword = (idUser: string): Promise<IAPIResponseInterface> => {
    return apiCall.patch('/account/accounts/'+idUser);
};


//   API des profils utilisateur 
export const getAllProfils = (): Promise<IAPIResponseInterface> => {
    return apiCall.get('/account/profil');
};

