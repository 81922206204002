import React from "react";
import classnames from "classnames";
import "./Loader.styles.scss";
import { CircularProgress } from "@mui/material";

export const Loader: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  return (
    <div className={classnames("loader-container", { active: isLoading })}>
      <span> Chargement </span>
      <CircularProgress />
    </div>
  );
};
