import { TAuthUser } from "@/types";
import { AuthAction } from "./auth.actions";
import authTypes from "./auth.types";

interface IAuthReducerState {
  jwt: string;
  user: TAuthUser | null;
  loading: boolean;
  rememberMe: boolean;
  errors: string;
}

const INITIAL_STATE = {
  user: null,
  jwt: "",
  loading: false,
  rememberMe: false,
  errors: "",
};

const authReducer = (
  state: IAuthReducerState = INITIAL_STATE,
  action: AuthAction
): IAuthReducerState => {
  switch (action.type) {
    case authTypes.GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
        errors: "",
      };

    case authTypes.GET_USER_TOKEN:
      return {
        ...state,
        jwt: action.payload,
      };

    case authTypes.AUTH_LOADING:
      return {
        ...state,
        loading: true,
        errors: "",
      };

    case authTypes.AUTH_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    case authTypes.REMEMBER_ME_AUTH:
      return {
        ...state,
        rememberMe: action.payload,
      };

    case authTypes.LOGOUT:
      return {
        ...state,
        user: null,
        loading: false,
        rememberMe: false,
        jwt: "",
        errors: "",
      };

    default:
      return state;
  }
};

export default authReducer;
