import { Skeleton } from '@mui/material';
import React from 'react';
import "./CampagneItemSkeleton.styles.scss";


export const CampagneItemSkeleton:React.FC = () => {

  return (
    <>
        <div  className='campagne-item-skeleton'>
            <Skeleton animation="wave" variant="text" className="campagne-title" />
            <div className="campagne-type">
                <Skeleton animation="pulse" variant="circular" className="img" />
                <Skeleton animation="pulse" variant="circular" className="img" />
                <Skeleton animation="pulse" variant="circular" className="img" />
            </div>
            <Skeleton animation="wave" variant="text" className="campagne-description" />
        </div>
    </>
  )
}
