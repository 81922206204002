import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import GlobalStyle from "./GlobalStyle.styles";

const testTheme = createTheme();

export const theme = {
  ...testTheme,
  colors,
};

export default GlobalStyle;
