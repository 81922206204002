import  NavBar  from './components/navbar/NavBar';
import  Sidebar  from './components/sidebar/Sidebar';
import React, { useState } from 'react';
import './UserSpace.styles.scss';
import { CampagnesRoutes, UserSpaceRoutes } from './routes';
import { createStructuredSelector } from 'reselect';
import { selectUserConnected } from '@/store/reducers/authentification/authentification.selector';

import { ConnectedProps , connect } from 'react-redux';

type PropsFromRedux = ConnectedProps<typeof connector>;
 const UserSpace:React.FC<PropsFromRedux> = ({userConnected}) => {

  const [isCamapagnePage, setIsCamapagnePage] = useState(true);

  return (
    <div className='userspace-layout'>
      <div className="userspace-nav">
        <NavBar setIsCamapagnePage={setIsCamapagnePage} userConnected={userConnected} />
      </div>
      
      { isCamapagnePage &&   
      <div className="userspace-content">
        <div className="userspace-side">
          <Sidebar />
        </div>
        <div className="userspace-body">
          <div className="userspace-body-route-layout">
              <CampagnesRoutes />
          </div>
        </div>
      </div>
      }
      
      {!isCamapagnePage &&  
      <div className="userspace-content">
        <div className="userspace-body">
          <div className="userspace-body-route-layout">
            <>
              <UserSpaceRoutes />
            </>
          </div>
        </div>
      </div>
      }
    </div>
  )
}
const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps); 

export default connector(UserSpace);