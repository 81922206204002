export enum campagneTypes {
    CAMPAGNE_LOADING = "CAMPAGNE_LOADING",
    CAMPAGNE_DETAIL_LOADING = "CAMPAGNE_DETAIL_LOADING",
    CAMPAGNE_CREATE_LOADING = "CAMPAGNE_CREATE_LOADING",
    GET_ALL_CAMPAGNE = "GET_ALL_CAMPAGNE",
    GET_SELECTED_CAMPAGNE = "GET_SELECTED_CAMPAGNE",
    FILTER_SELECTED_CAMPAGNE = "FILTER_SELECTED_CAMPAGNE",
    SEARCH_CAMPAGNE = "SEARCH_CAMPAGNE",
    SELECTED_ID = "SELECTED_ID",
}
  
export default campagneTypes;