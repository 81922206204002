import { createUser, updateUser, getAllProfils } from '@/pages/userspace/api/user.api';
import { TProfil, TUser } from '@/types';
import { ToastError, ToastGeneric } from '@/utils/toast';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import './user-create.styles.scss'; 


type TUserFormProps = {
    user?: TUser,
    refreshUserList: React.Dispatch<React.SetStateAction<string>>,
    closeSidePanel: React.Dispatch<React.SetStateAction<boolean>>,
}

const UserCreate: React.FC<TUserFormProps> = ({user, closeSidePanel, refreshUserList}) => {

    const [profilList, setProfilList] = useState<TProfil[]>([]);

    /**
    *  Gestion du formulaire d'ajout d'un utilisateur
    */
    const validationSchema = yup.object({
        nom: yup.string().required('Veuillez renseigner le nom'),
        prenoms: yup.string().required('Veuillez renseigner les prénoms'),
        email: yup.string().email('Adresse email invalide').required('Adresse email obligatoire'),
        profil_id: yup.string().required('Veuilliez selectionner profil'),
        telephone: yup.number().required('Veuillez '),
    });

    const formik = useFormik({
        initialValues: {
            nom: user?.nom || "",
            prenoms: user?.prenoms || "",
            email: user?.email || "",
            profil_id: user?.profil_id || "",
            telephone: user?.telephone || "",
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            // alert(JSON.stringify(values, null, 2));
            const response = !user?.id ? await createUser(values) : await updateUser(values, user.id);
            if (response.error) {
                ToastError.fire({ text: response.message, timer: 9000 });
            } else {
                !user?.id ? ToastGeneric("Utilisateur créé avec succès, il aura par mail ces accès", "success", 10000).fire() : ToastGeneric("Utilisateur mis à jour avec succès", "success", 10000).fire();
                closeSidePanel(false)
                refreshUserList(new Date().getTime.toString());
            }
        },
    });

    // La liste des types d'acteurs 
  const getProfilList = async () => {
    const { data } = await getAllProfils();
    const dataWithNewObjectKeys: TProfil[] = data.map((Profil: any) => {
      return {
        id: Profil.id,
        libelle: Profil.libelle,
      };
    });
    setProfilList(dataWithNewObjectKeys);
  }

  useEffect(() => {
    getProfilList();
  },[]);

  return (
    <>
        <div className='user-create-container'>
            <form onSubmit={formik.handleSubmit} className='row'>
                <div className='col-12 mb-3'>
                    <input 
                        type="text"
                        className='form-control' 
                        placeholder='Nom' 
                        name='nom' 
                        onChange={formik.handleChange}
                        value={formik.values.nom}
                    />
                </div>

                <div className='col-12 mb-3'>
                    <input 
                        type="text" 
                        className='form-control' 
                        placeholder='Prénoms' 
                        name='prenoms' 
                        onChange={formik.handleChange}
                        value={formik.values.prenoms}
                    />
                </div>

                <div className='col-12 mb-3'>
                    <input 
                        type="email" 
                        className='form-control' 
                        placeholder='Email' 
                        name='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </div>

                <div className='col-12 mb-3'>
                    <select 
                        className="form-select" aria-label="Default select example"
                        name="profil_id"
                        placeholder='Choisir un profil'
                        onChange={formik.handleChange}
                        value={formik.values.profil_id}
                    >
                        <option value=""></option>
                        {profilList.map(profil => <option key={profil.id} value={profil.id!}>{profil.libelle}</option>)}
                        {/* <option value="administrateur">Administrateur</option>
                        <option value="lecteur">Lecteur</option> */}
                    </select>
                </div>

                <div className='col-12 mb-3'>
                    <input 
                        type="tel" 
                        className='form-control' 
                        placeholder='Numéro de téléphone' 
                        name='telephone'
                        onChange={formik.handleChange}
                        value={formik.values.telephone}
                    />
                </div>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={formik.isSubmitting || !formik.isValid}
                    >
                    {user?.id ? "Modifier" : "Enregistrer"}
                </Button>
            </form>
        </div>
    </>
  )
}

export default UserCreate