import { CampagneService } from "@/services/campagne.service";
import { TCampagneItem, TCampagneDetail, TCampagne } from "@/types";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import campagneTypes from "./campagne.types";

interface ISetLoadingCampagne {
  type: campagneTypes.CAMPAGNE_LOADING;
  payload: boolean;
}

interface ISetLoadingCampagneDetail {
  type: campagneTypes.CAMPAGNE_DETAIL_LOADING;
  payload: boolean;
}

interface ISetLoadingCampagneCreate {
  type: campagneTypes.CAMPAGNE_CREATE_LOADING;
  payload: boolean;
}

interface IGetAllCampagne {
  type: campagneTypes.GET_ALL_CAMPAGNE;
  payload: Array<TCampagneItem>;
}

interface IGetCampagneDetail {
  type: campagneTypes.GET_SELECTED_CAMPAGNE;
  payload: TCampagneDetail;
}

interface IFilterSelectedCampagne {
  type: campagneTypes.FILTER_SELECTED_CAMPAGNE;
  payload: Array<TCampagneItem>;
}

interface ISearchCampagne {
  type: campagneTypes.SEARCH_CAMPAGNE;
  payload: Array<TCampagneItem>;
}

interface ISelectedId {
  type: campagneTypes.SELECTED_ID;
  payload: string;
}

export type CampagneAction =
  | ISetLoadingCampagne
  | ISetLoadingCampagneDetail
  | ISetLoadingCampagneCreate
  | IGetAllCampagne
  | IGetCampagneDetail
  | IFilterSelectedCampagne
  | ISearchCampagne
  | ISelectedId;

export const setLoadingCampagne = (loading: boolean) => ({
  type: campagneTypes.CAMPAGNE_LOADING,
  payload: loading,
});

export const setLoadingCampagneDetail = (loading: boolean) => ({
  type: campagneTypes.CAMPAGNE_DETAIL_LOADING,
  payload: loading,
});

export const setLoadingCampagneCreate = (loading: boolean) => ({
  type: campagneTypes.CAMPAGNE_CREATE_LOADING,
  payload: loading,
});

export const getAllCampagne = (campagneList: Array<TCampagneItem>) => ({
  type: campagneTypes.GET_ALL_CAMPAGNE,
  payload: campagneList,
});

export const getCampagneDetail = (campagne: TCampagneDetail) => ({
  type: campagneTypes.GET_SELECTED_CAMPAGNE,
  payload: campagne,
});

export const filterSelectedCampagne = (
  campagneList: Array<TCampagneItem>,
  id: string
) => ({
  type: campagneTypes.FILTER_SELECTED_CAMPAGNE,
  payload: campagneList.map((value) => {
    // console.log("############", id);
    return {
      ...value,
      isActive: value.id === id,
    };
  }),
});

export const searchCampagne = (
  campagneList: Array<TCampagneItem>,
  searchValue: string
) => ({
  type: campagneTypes.SEARCH_CAMPAGNE,
  payload: campagneList.filter((elt) => {
    return elt.libelle.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1;
  }),
});

export const setSelectedId = (id: string) => ({
  type: campagneTypes.SELECTED_ID,
  payload: id,
});

export const createCampagneAsync =
  (campagne: TCampagne) => (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(setLoadingCampagneCreate(true));
    CampagneService.createCampagne(campagne);
  };

export const getAllCampagneAsync =
  () => (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(setLoadingCampagne(true));
    CampagneService.getCampagneItemList();
  };

export const getCampagneDetailAsync =
  (id: string) => (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(setLoadingCampagneDetail(true));
    CampagneService.getSelectedCampagne(id);
  };
