import authTypes from "./auth.types";
import {
  TAuthUser,
  //TCredentials
}
  from "@/types";

// import { AuthService } from "../../../services/auth.service";
// import { ThunkDispatch } from "redux-thunk";
// import { AnyAction } from "redux";

interface IGetUserAction {
  type: authTypes.GET_USER;
  payload: TAuthUser;
}

interface IGetUserToken {
  type: authTypes.GET_USER_TOKEN;
  payload: string;
}

interface ISetLoadingAuthAction {
  type: authTypes.AUTH_LOADING;
}

interface ISetErrorsAuthAction {
  type: authTypes.AUTH_ERRORS;
  payload: string;
}

interface ISetRememberMeAction {
  type: authTypes.REMEMBER_ME_AUTH;
  payload: boolean;
}

interface ILogoutAction {
  type: authTypes.LOGOUT;
  payload: boolean;
}

export type AuthAction =
  | IGetUserAction
  | IGetUserToken
  | ISetLoadingAuthAction
  | ISetErrorsAuthAction
  | ISetRememberMeAction
  | ILogoutAction;

export const getUser = (user: TAuthUser) => ({
  type: authTypes.GET_USER,
  payload: user,
});

export const getUserToken = (token: string) => ({
  type: authTypes.GET_USER_TOKEN,
  payload: token,
});

export const setLoadingAuth = () => ({
  type: authTypes.AUTH_LOADING,
});

export const setErrorsAuth = (errors: string) => ({
  type: authTypes.AUTH_ERRORS,
  payload: errors,
});

export const setRememberMeAuth = (isRememberMe: boolean) => ({
  type: authTypes.REMEMBER_ME_AUTH,
  payload: isRememberMe,
});

export const logout = () => ({
  type: authTypes.LOGOUT,
});
